import React from "react"
import styled from "styled-components"
import MainTemplate from 'templates/MainTemplate/MainTemplate'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
  background-color: #171717;
  padding: 100px 50px;
`

const Title = styled.h1`
  font-size: 400px;
  font-family: ${({ theme }) => theme.font.family.bebas};
	font-weight: 500;
  color: #7fdc43;
  text-align: center;
  margin: 0;

  @media (max-width: 720px) {
    font-size: 200px;
  }

  @media (max-width: 460px) {
    font-size: 120px;
  }
`

const Text = styled.p`
  font-size: 90px;
  font-family: ${({ theme }) => theme.font.family.bebas};
	letter-spacing: 5px;
	font-weight: 500;
  color: #7fdc43;
  text-align: center;
  margin-top: 0;

  @media (max-width: 720px) {
    font-size: 80px;
  }

  @media (max-width: 460px) {
    font-size: 40px;
  }
`

const NotFoundPage = () => (
  <MainTemplate>
    <PageWrapper>
      <Title>
        404
      </Title>
      <Text>
        We couldn't find the page you're looking for
      </Text>
    </PageWrapper>
  </MainTemplate>
)

export default NotFoundPage
